import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://bf32ad7da6eca09a4bd543960427cd7c@o4508059553955840.ingest.us.sentry.io/4508061763502080",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});